import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #231f20;
    --grey: #eaeaea;
    --dark-grey: #7a7575;
    --white: #fff;
    --teal: #1aa687;
    --red: #ff414d;
    --baby-blue: #d9ecf2;
    --blue: #027397;
    --whitespace-primary: 2rem;
    --whitespace-secondary: 3rem;
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    height: 100vh;
  }
  
  main {
    height: 100vh;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 4vw 2vw;
  }

  article {
    padding: var(--whitespace-secondary) 0;
  }

  .m-top-3 {
    margin-top: var(--whitespace-secondary);
  }

  .m-top-4 {
    margin-top: 4rem;
  }

  .sr-only {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
  }
`;

export default GlobalStyles;
